import { ContentPageLayout } from '@components/Layout';
import React, { FC, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/index';
import { Button, Link } from '@components/Primitives';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Checkbox } from '@components/Checkbox';
import styled from 'styled-components';
import { FormattedMessage as M, navigate } from 'gatsby-plugin-intl';
import { BlueBox } from '@components/BlueBox';
import { TextSection } from '@components/TextSection';
import moment from 'moment';
import { getEventTicket } from '../../services/api';
import FileSaver from 'file-saver';
import { Input } from '@primitives';

interface IEventsPage {
  location: any;
}

const EventsPage: FC<IEventsPage> = observer(({
  location,
}) => {
  const Resident = styled.div`
  display: flex;
  .label {
    margin-left: 12px;
  }
`;
  const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  `;

  const Extra = styled.div`
  display: flex;
  margin-bottom: 12px;
  button {
    margin-left: 12px;
  }
`;
    const [editing, setEditing] = useState<boolean>(false)
    const { event } = queryString.parse(location.search);
    const {
      eventStore: { getEventRegistrations, eventRegistrations, updateEventRegistrations },
      agreementStore: { selectedAgreementId },
      toastStore: { showError },
      currentUserStore: { currentUser, getCurrentUser }
    }: {
      eventStore: IEventStore,
      agreementStore: IAgreementStore,
      toastStore: IToastStore;
      currentUserStore: ICurrentUserStore
    } = useStores();

    const eventRegistration = useMemo(() => {
      if (!event || !eventRegistrations) return undefined;
      return eventRegistrations?.find((eventRegistration) => eventRegistration.eventid === parseInt(event as string));
    }, [event, eventRegistrations]);
  
    useEffect(() => {
      if (event && selectedAgreementId) {
        getEventRegistrations(event as string)
      }
    }, [event, selectedAgreementId]);

    useEffect(() => {
      if (!currentUser) {
        getCurrentUser()
      }
    }, [currentUser])
    
    useEffect(() => {
      if (!event) navigate('/');
    }, [event]);

    const isRegistrationClosed = useMemo(() => {
      if (!eventRegistration) return true;
      return moment(eventRegistration.registration_end).isBefore(moment().toISOString());
    }, [eventRegistration]);

    const initialValues = useMemo(() => ({
      residents: eventRegistration?.residents || [],
      extras: eventRegistration?.extras || [],
      ...eventRegistration,
    }), [eventRegistration]);
    

    const onSubmit = async (values: IEventRegistration, form: any, cb: any) => {
      const { residents, extras } = values;
      const registeredResidents = residents.filter((resident) => resident.registered);
      await updateEventRegistrations(event as string, { residents: registeredResidents, extras }, cb);
      form.reset();
      setEditing(false)
    };
  
    
    const onDownloadClick = async () => {
      const strId = String(selectedAgreementId);
      try {
        const response = await getEventTicket(strId, event as string);
        const blob = new Blob([response.data]);
        FileSaver.saveAs(blob, `lippu-${event}.pdf`);
      } catch (e: any) {
        let errorText = 'pages.events.downloadTicketError';
        if (e?.response?.status === 404) {
          errorText = 'pages.events.downloadTicketError404';
        }
        // @ts-ignore
        showError(errorText, true);
      }
    }

    const count = eventRegistration && eventRegistration?.residents?.filter((resident) => resident?.registered).length + eventRegistration?.extras?.length
    const registeredResidents = eventRegistration?.residents.some((resident) => resident.registered);
    let language = typeof localStorage !== 'undefined' ? localStorage.getItem('language') : null;

    const renderConditionalHeading = () => {
      const divStyle = { backgroundColor: '', height: '54px', marginBottom: '20px' };
      const textStyle = { textAlign: 'center', lineHeight: '54px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, fontWeight: 300 }
      divStyle.backgroundColor = registeredResidents ? "#96FFD9" : "#FCAF67";

      const registeredText = language === 'fi' ? `Olet ilmoittanut ${count} osallistujaa` : `You have enrolled ${count} participants:`
      const notRegisteredText = language === 'fi' ? "Et ole vielä ilmoittautunut" : "You have not enrolled yet"
      const displayText = registeredResidents ? registeredText : notRegisteredText;
      return <div style={divStyle}> <text style={textStyle}>{displayText}</text></div>
    }

    const renderBoxHeader = () => {
      const headingTextEditing = language === 'fi' ? 'Valitse asukkaat, jotka osallistuvat asukaspäivään:' : 'Select the residents who will take part in the Residents’ Day:'
      const headingTextNotEditing = language === 'fi' ? 'Ilmoittamasi osallistujat:' : `The enrolled participants:`
      const displayText = editing ? headingTextEditing : headingTextNotEditing;
      return <text style={{textAlign: 'left', fontSize: 20, paddingBottom: 25}}> {displayText} </text>
    }
    
    const renderHeaderText = () => {
      const link = <Link to='/me/residents/'> <strong> Omat tiedot – Muut asukkaat </strong> </Link>
      if (language === 'fi') {
      return (
      <text> 
      <p>Tervetuloa Särkänniemeen YH Kotien asukaspäivään sunnuntaina 15.6.2025 klo 10-13, jolloin huvipuisto on varattu YH Kotien käyttöön.</p>
      <p> Ilmoittaudu mukaan YH Kotien asukaspäivään Särkänniemeen 11.5.2025 mennessä alla olevasta “Poista tai lisää osallistujia” painikkeen kautta. </p>
      <p> <strong>Saat pääsylippusi</strong> ladattavana tiedostona tähän sivulle ja sähköpostiisi <strong>ilmoittautumisen päättymisen jälkeen</strong> (12.5.2025). Siihen asti voit muokata osallistujalistaasi. </p>
      <p> Puuttuuko listalta asukkaita? Käy lisäämässä henkilöt {link} -linkin kautta. Samalla tieto menee myös huoltoyhtiön talonkirjoihin. </p>
      </text>
      )
      }
      else if (language === 'en') {
      return (
      <text> 
       <p>You are very welcome to Särkänniemi amusement park on Sunday 15th of June 2025. The park is reserved for private use for YH Kodit from 10 am to 1 pm.</p>
      <p> Enrol for the YH Kodit Residents’ Day in Särkänniemi Amusement Park by 11 May 2025 via the “Add or remove participants” button below. </p>
      <p> <strong>You will receive your entrance ticket</strong> as a download on this page and to your e-mail <strong>once the enrolment period has ended</strong> (12 May 2025). Until then, you can edit your list of participants.</p>
      <p> Is a resident missing from the list? You can add residents through the {link} (My information – other residents) link. The information will also be added to the maintenance company’s register of occupants.</p>
      </text>
      )
      }
      else return null
    }

    const renderFooterText = () => {
      const link = <Link to='/messages/'> <strong> {language === 'fi' ? 'YH Kotien asiakaspalveluun.' : 'customer service of YH Kodit.'} </strong> </Link>
      if (language === 'en') {
        return (
        <text>
        <p>Please note that only a limited number of participants can be given access to Särkänniemi Amusement Park. If you have any problems with the enrolment process, please contact the {link}</p>
        </text>
        )
      }
      else if (language === 'fi') {
        return (
        <text>
        <p>Huomioithan, että Särkänniemeen voidaan ottaa vain rajattu määrä osallistujia. Jos sinulla on ongelmia ilmoittautumisen kanssa, ota yhteyttä {link}</p>
        </text>
        )
      }
    }
    const renderContent = () => {
      const [isInputEmpty, setIsInputEmpty] = useState(true); // Add state for input value
    
      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsInputEmpty(e.target.value === ''); // Set state based on input value
      };
    
      const handleClick = () => {
        const inputEl = document.getElementById('extraName') as HTMLInputElement;
        const newExtra = { name: inputEl.value };
        eventRegistration?.extras.push(newExtra);
        inputEl.value = ''; // Clear input after adding extra
        setIsInputEmpty(true); // Reset state to disable button
      };
    
      if (eventRegistration && editing && !isRegistrationClosed) {
        return (
          <>
            {renderHeaderText()}
            <Form
              onSubmit={onSubmit}
              mutators={{ ...arrayMutators }}
              initialValues={initialValues}
              render={({ handleSubmit, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {renderConditionalHeading()}
                    <BlueBox>
                      <Resident>
                        {renderBoxHeader()}
                      </Resident>
                      <FieldArray name="residents">
                        {({ fields }) => (
                          <>
                            {fields.map((name, index) => {
                              return (
                                <Resident key={name}>
                                  <Field type="checkbox" name={`${name}.registered`}>
                                    {props => (
                                      // @ts-ignore
                                      <Checkbox id={name} {...props.input} />
                                    )}
                                  </Field>
                                  <label className="label" htmlFor={name}>{`${values.residents[index].name}`}</label>
                                </Resident>
                              );
                            })}
                          </>
                        )}
                      </FieldArray>
                      {eventRegistration.extras?.map((extra, index) => (
                        <div style={{ display: 'flex' }} key={index}>
                          <Resident>
                            <Checkbox id={extra.name} onChange={() => eventRegistration.extras.splice(index, 1)} checked />
                            <div className="label">{extra.name}</div>
                          </Resident>
                        </div>
                      ))}
                      <text style={{display: 'flex', marginTop: 20}}> {language === 'fi' ? 'Tarvittaessa voit ottaa mukaan yhden ulkopuolisen vieraan esimerkiksi laitekaverin taitoisen aikuisen.' : 'If necessary, you can bring along an outside guest, for example a friend for your child or another adult.'}</text>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                      <Input id="extraName" type="text" onChange={handleInputChange} />
                      <Button onClick={handleClick} disabled={isInputEmpty || eventRegistration.extras.length >= 2}>
                        {language === 'fi' ? 'Lisää' : 'Add'}
                      </Button>
                      </div>
                      <Button mt='1' type='submit'>
                        {language === 'fi' ? 'Tallenna ilmottautuminen' : 'Submit'}
                      </Button>
                    </BlueBox>
                    {renderFooterText()}
                  </form>
                )
              }}
            />
          </>
        )
      }    

    else if (!editing && !isRegistrationClosed) {
      const residentsToDisplay = eventRegistration?.residents?.filter(resident => resident.registered)
      const extrasToDisplay = eventRegistration?.extras
      return (
        <div>
        {renderHeaderText()}
        {renderConditionalHeading()}
        <BlueBox>
        <Resident>
        {renderBoxHeader()}
        </Resident>
        { residentsToDisplay?.map((resident, index) => (
          <div style={{marginTop: 20, marginLeft: -10}}>
          <Resident key={index}>
            <div className="label">{resident.name}</div>
          </Resident>
          </div>
       ))}
        { extrasToDisplay?.map((extra, index) => (
          <div style={{marginTop: 20, marginLeft: -10}}>
          <Resident key={index}>
            <div className="label">{extra.name}</div>
          </Resident>
          </div>
        ))}
       <Button mt='1' onClick={() => setEditing(true)}>
        {language === 'fi' ? 'Lisää tai poista osallistujia' : 'Add or remove participants'}
       </Button>
       </BlueBox>
       {renderFooterText()}
       </div>
      )
      
    }

    else if (isRegistrationClosed && eventRegistration) {
      const residentsToDisplay = eventRegistration?.residents?.filter(resident => resident.registered)
      const extrasToDisplay = eventRegistration?.extras
      return (
        <div>
        <div>
        <TextSection title={eventRegistration?.name} />
        <CenteredContent>
          <div dangerouslySetInnerHTML={{ __html: eventRegistration?.ticket_description }} />
          <Button onClick={onDownloadClick}> {language === 'fi' ? 'Lataa lippu (PDF)' : 'Download ticket (PDF)'} </Button>
        </CenteredContent>
        </div>
            <div style={{marginTop: 50}}>
                {renderConditionalHeading()}
                <BlueBox>
                <Resident>
                {renderBoxHeader()}
                </Resident>
                { residentsToDisplay?.map((resident, index) => (
                  <div style={{marginTop: 20, marginLeft: -10}}>
                  <Resident key={index}>
                    <div className="label">{resident.name}</div>
                  </Resident>
                  </div>
               ))}
               { extrasToDisplay?.map((extra, index) => (
                  <div style={{marginTop: 20, marginLeft: -10}}>
                    <Resident key={index}>
                      <div className="label">{extra.name}</div>
                    </Resident>
                  </div>
               ))}
               </BlueBox>
            </div>
        </div>
      )
    }

    else return null
  }

  return (
    <ContentPageLayout wideContent>
      {renderContent()}
    </ContentPageLayout >
  )
})

export default EventsPage